import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const MapComponent = (props) => {
  return (
    <Map
      google={props.google}
      zoom={16}
      initialCenter={{
        lat: props.lat,
        lng: props.lng,
      }}
      center={{
        lat: props.lat,
        lng: props.lng,
      }}
      style={props.style}
      className={props.className}
    >
      {props.lat && props.lng && (
        <Marker
          position={{
            lat: props.lat,
            lng: props.lng,
          }}
        ></Marker>
      )}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.REACT_APP_APIKEY,
  LoadingContainer: null,
})(MapComponent);
